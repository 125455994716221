import React, { useState } from "react";
import { Layout, Menu, Modal, Button, Form, Input, Select } from "antd";
import RichTextExample from "./RichText";
import { AddPost } from "../ApiHooks";
import { useHistory } from "react-router-dom";
import "./layout.scss";
import moment from "moment";
import { Link } from "react-router-dom";
const { Option } = Select;
const { Header, Content, Footer } = Layout;
const timestamp = moment().valueOf();

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const initialValue = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];
const Home = (props) => {
  const { authenticated } = props;
  const [auth, setAuth] = useState(authenticated);
  const [children, setChildren] = useState(props.children);
  const [showModal, setShowModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();
  const [richValue, setRichValue] = useState(initialValue);

  const onFinish = async (values) => {
    setSubmitting(true);
    let id = Math.random().toString().slice(2, 11);
    let new_values = {
      ...values,
      rich_description: richValue,
      timestamp,
      id: parseInt(id),
      content: "",
    };

    try {
      await AddPost(new_values);
      setChildren(props.children);
      setSubmitting(false);
      setShowModal(false);
      history.push(`/post/${new_values.category}/${new_values.id}`);
    } catch (e) {
      setSubmitting(false);
      setShowModal(false);
    }
  };
  const logout = () => {
    localStorage.clear();
    setAuth(false);
    history.push(`/`);
    window.location.reload();
  };
  return (
    <Layout className="layout">
      <Header>
        <div className="logo" />
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={["1"]}>
          <Menu.Item key="1">
            <Link to="/">Home</Link>
          </Menu.Item>
          {auth && (
            <Menu.Item onClick={() => setShowModal(true)} key="2">
              Add Post
            </Menu.Item>
          )}
          {auth && (
            <Menu.Item onClick={logout} key="3">
              Log out
            </Menu.Item>
          )}
        </Menu>
      </Header>
      <Content>{children}</Content>
      <Footer style={{ textAlign: "center" }}>
        Espire Ads ©{moment().format("YYYY")}
      </Footer>
      {showModal && (
        <Modal
          closable={false}
          className="edit-modal"
          title={`Add blog:  `}
          visible={showModal}
          footer={[
            <Button key="back" onClick={() => setShowModal(false)}>
              Cancel
            </Button>,
            <Button
              loading={submitting}
              key="submit"
              htmlType="submit"
              form="addForm"
              type="primary"
            >
              Submit
            </Button>,
          ]}
        >
          <Form
            {...layout}
            id="addForm"
            name="basic"
            // initialValues={{ [``]: data[editingField] }}
            onFinish={onFinish}
          >
            <Form.Item
              name="category"
              label="Category"
              rules={[{ required: true }]}
            >
              <Select placeholder="Select category" allowClear>
                <Option value="featured">Featured</Option>
                <Option value="news">News</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Title" name="title">
              <Input />
            </Form.Item>
            <Form.Item label="Author" name="author">
              <Input />
            </Form.Item>
            <Form.Item label="Image Url" name="image">
              <Input />
            </Form.Item>
            <Form.Item name="content">
              <RichTextExample
                setRichValue={setRichValue}
                richValue={richValue}
                isEditable={true}
              />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </Layout>
  );
};
export default Home;
