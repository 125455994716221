import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "../pages/Home";
import CategoryPage from "../pages/CategoryPage";
import BlogPost from "../pages/BlogPost";
import LoginPage from "../pages/LoginPage";
import Layout from "./Layout";
const App = () => {
  const auth = localStorage.getItem("authenticated");

  //   if (!auth) {
  //     return (
  //       <Router>
  //         <Switch>
  //           <Layout authenticated={auth}>
  //             <Route path="/" exact render={() => <LoginPage />} />
  //             <Route path="/login" exact render={() => <LoginPage />} />
  //           </Layout>
  //         </Switch>
  //       </Router>
  //     );
  //   }
  return (
    <Router>
      <Switch>
        <Layout authenticated={auth}>
          <Route path="/" exact render={() => <Home />} />
          <Route path="/home" exact render={() => <Home />} />
          <Route path="/post/:category/:id" component={BlogPost} />
          <Route path="/post/:category" exact component={CategoryPage} />
          <Route path="/login" exact component={LoginPage} />
        </Layout>
      </Switch>
    </Router>
  );
};
export default App;
