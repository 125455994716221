import React, { useState } from "react";
import { Card, Empty, Breadcrumb, Divider, Spin, Typography } from "antd";
import { Modal, Tag, Input, Form, Button, Select } from "antd";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import RichTextExample from "../components/RichText";
import {
  FetchSingleArticle,
  ArtcleUID,
  UpdateField,
  AddPost,
  DeletePost,
} from "../ApiHooks";
import { Link } from "react-router-dom";
import "./blogpost.scss";
import moment from "moment";
const { confirm } = Modal;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const { Option } = Select;
const { Title } = Typography;
const initialValue = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];
const ArticleCard = (props) => {
  const history = useHistory();

  const auth = localStorage.getItem("authenticated");
  const { category, id } = props.match.params;
  const [data, isLoading, error, fetchData] = FetchSingleArticle(category, id);
  const [uid] = ArtcleUID(category, id);
  const [showModal, setShowModal] = useState(false);
  const [editingField, setEditingField] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [richValue, setRichValue] = useState(initialValue);

  const editModal = (field, data) => {
    setRichValue(data.rich_description);
    setEditingField(field);
    setShowModal(!showModal);
  };
  const onFinish = async (values) => {
    setUpdating(true);
    let field = Object.entries(values).map((x) => x[0]);
    let value = Object.entries(values).map((x) => x[1]);
    try {
      if (values.content === "") {
        let update = await UpdateField(
          uid,
          category,
          "rich_description",
          richValue
        );
        update && fetchData(category, id);
      }
      if (field[0] === "category") {
        await AddPost({ ...data, category: value[0] });
        await DeletePost(data.category, uid);
        fetchData(value[0], id);
      } else {
        let update = await UpdateField(uid, category, field[0], value[0]);
        update && fetchData(category, id);
      }
    } catch (e) {
      setShowModal(false);
    } finally {
      setShowModal(false);
      setUpdating(false);
    }
  };
  const showDeleteConfirm = async () => {
    try {
      confirm({
        title: "Are you sure delete this post?",
        icon: <ExclamationCircleOutlined />,
        content: `Title : ${data.title}`,
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk() {
          DeletePost(category, uid);
          history.push(`/`);
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    } catch (e) {
      console.log("e", e);
    }
  };
  return (
    <div className="blog-post">
      <Spin spinning={isLoading}>
        {isLoading ? (
          <Card style={{ width: "100%", marginTop: 16 }} loading={true} />
        ) : error ? (
          <Empty description={<span>{error}</span>} />
        ) : (
          <div>
            <Breadcrumb style={{ marginBottom: "1rem" }}>
              <Breadcrumb.Item>
                <Link to={"/"}>Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/post/${category}`}>{category}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{data.title}</Breadcrumb.Item>
            </Breadcrumb>
            <Title>
              {data.title}
              {auth && (
                <>
                  <Tag color="#f50" onClick={() => editModal("title", data)}>
                    Edit
                  </Tag>
                  <Tag color="red" onClick={showDeleteConfirm}>
                    Delete
                  </Tag>
                </>
              )}
            </Title>
            <span>
              <Link to={`/post/${data.category}`}>
                <Tag color="#108ee9" style={{ textTransform: "capitalize" }}>
                  {data.category}
                </Tag>
              </Link>
              {auth && (
                <Tag color="#f50" onClick={() => editModal("category", data)}>
                  Edit
                </Tag>
              )}
            </span>
            <h3>{`${data.author} | ${moment(data.timestamp).format(
              "MMM Do, YYYY"
            )}`}</h3>
            {data.image && (
              <div className="blog-post-img-container">
                <img alt="" className="blogpost-image" src={data.image} />
                <div className="topright">
                  {auth && (
                    <Tag color="#f50" onClick={() => editModal("image", data)}>
                      Edit
                    </Tag>
                  )}
                </div>
              </div>
            )}
            <Divider />
            {auth && (
              <Tag
                color="#f50"
                onClick={() => {
                  editModal("content", data);
                }}
              >
                Edit
              </Tag>
            )}
            <div className="rich-content-display">
              <RichTextExample
                richValue={data.rich_description}
                setRichValue={() => {}}
                isEditable={false}
              />
            </div>
          </div>
        )}
        {isLoading || (
          <Modal
            closable={false}
            className="edit-modal"
            title={`Editing blog: ${data.id}`}
            visible={showModal}
            footer={[
              <Button key="back" onClick={() => setShowModal(false)}>
                Cancel
              </Button>,
              <Button
                loading={updating}
                key="submit"
                htmlType="submit"
                form="editForm"
                type="primary"
              >
                Submit
              </Button>,
            ]}
          >
            <Form
              {...layout}
              id="editForm"
              name="basic"
              initialValues={{ [`${editingField}`]: data[editingField] }}
              onFinish={onFinish}
            >
              <Form.Item
                label={editingField === "content" ? null : editingField}
                name={editingField}
              >
                {editingField === "category" ? (
                  <Select placeholder="Select category" allowClear>
                    <Option value="featured">Featured</Option>
                    <Option value="news">News</Option>
                  </Select>
                ) : editingField === "content" ? (
                  <RichTextExample
                    richValue={richValue}
                    setRichValue={setRichValue}
                    isEditable={true}
                  />
                ) : (
                  <Input allowClear />
                )}
              </Form.Item>
            </Form>
          </Modal>
        )}
      </Spin>
    </div>
  );
};
export default ArticleCard;
