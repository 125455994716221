import React, { useState } from "react";
import { FetchArticles, ArtcleUID, DeletePost } from "../ApiHooks";
import { Row, Col, Card, Empty, Spin, Breadcrumb, Modal } from "antd";
import { useHistory, Link } from "react-router-dom";
import moment from "moment";
import "./categorypage.scss";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
const { confirm } = Modal;

const { Meta } = Card;
const noImage =
  "https://www.ira-sme.net/wp-content/themes/consultix/images/no-image-found-360x260.png";

const Article = (props) => {
  const [id] = useState(null);
  const history = useHistory();
  const type = props.match.params.category;
  const [data, isLoading, error, fetchData] = FetchArticles(type);
  const [, , , getUID] = ArtcleUID(type, id);
  const refreshData = async () => {
    try {
      let newdata = await fetchData(type);
      console.log(":newdata", newdata);
    } catch (e) {
      console.log("error", e);
    }
  };
  const showDeleteConfirm = async (item) => {
    try {
      const uid = await getUID(item.category, item.id);
      confirm({
        title: "Are you sure delete this post?",
        icon: <ExclamationCircleOutlined />,
        content: `Title : ${item.title}`,
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk() {
          DeletePost(item.category, uid);
          refreshData();
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    } catch (e) {
      console.log("e", e);
    }
  };
  return (
    <div className="category-page">
      <Breadcrumb style={{ marginBottom: "1rem" }}>
        <Breadcrumb.Item>
          <Link to={"/"}>Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{type}</Breadcrumb.Item>
      </Breadcrumb>
      <Spin spinning={isLoading}>
        <Row gutter={[8, 8]}>
          {isLoading ? (
            <Card
              style={{ width: "100%", marginTop: 16 }}
              loading={isLoading}
            />
          ) : error ? (
            <Empty description={<span>{error}</span>} />
          ) : (
            data.map((article, key) => {
              return (
                <Col xs={{ span: 12 }} lg={{ span: 8 }}>
                  <Card
                    cover={
                      <img
                        onClick={() =>
                          history.push(`/post/${type}/${article.id}`)
                        }
                        style={{ cursor: "pointer" }}
                        alt="example"
                        src={article.image ? article.image : noImage}
                      />
                    }
                    actions={[
                      // <EditOutlined key="edit/" />,
                      <DeleteOutlined
                        onClick={() => showDeleteConfirm(article)}
                        key="delete"
                      />,
                    ]}
                  >
                    <Meta
                      title={article.title}
                      description={`${article.author} | ${moment(
                        article.timestamp
                      ).format("MMM Do, YYYY")}`}
                    />
                  </Card>
                </Col>
              );
            })
          )}
        </Row>
      </Spin>
    </div>
  );
};
export default Article;
