import React from "react";
import { Carousel, Spin, Empty, Button } from "antd";
import "./widgets.scss";
import { FetchArticles } from "../ApiHooks";
import { useHistory } from "react-router-dom";

const Featured = () => {
  const history = useHistory();

  const [data, isLoading, error] = FetchArticles("featured");
  return (
    <div className="featured-carousel">
      <Spin spinning={isLoading}>
        <Carousel>
          {isLoading || error ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<span>{error}</span>}
            />
          ) : (
            data.map((article, key) => {
              return (
                <div key={key} className="carousel-div">
                  <div
                    className="carousel-img"
                    style={{
                      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${article.image})`,
                    }}
                  >
                    <h2 className="carousel-txt">{article.title}</h2>
                    <Button
                      onClick={() =>
                        history.push(`/post/featured/${article.id}`)
                      }
                    >
                      Read
                    </Button>
                  </div>
                </div>
              );
            })
          )}
        </Carousel>
      </Spin>
    </div>
  );
};
export default Featured;
