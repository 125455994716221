import { useState, useEffect } from "react";
import { db } from "./firebase";
import { sorted, find_by_id } from "./helpers";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
export function FetchArticles(types) {
  const [type] = useState(types);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const fetchData = async (type) => {
    try {
      let articles = await db.collection(type).get();
      let data = articles.docs.map((doc) => doc.data());
      if (data.length === 0) {
        throw new Error("");
      }
      let sortedData = sorted(data);
      setData(sortedData);
      return sortedData;
    } catch (e) {
      setError("No Articles Found");
      return e;
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData(type);
  }, [type]);
  return [data, isLoading, error, fetchData];
}
export function FetchSingleArticle(article_category, article_id) {
  const [category] = useState(article_category);
  const [id] = useState(article_id);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const fetchData = async (category, id) => {
    try {
      let articles = await db.collection(category).get();
      let data = articles.docs.map((doc) => doc.data());
      if (data.length === 0) {
        throw new Error("");
      }
      let findByID = find_by_id(data, parseInt(id));
      setData(findByID);
      return findByID;
    } catch (e) {
      setError("No Articles Found");
      return e;
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData(category, id);
  }, [category, id]);
  return [data, isLoading, error, fetchData];
}

export function ArtcleUID(article_category, article_id) {
  const [category] = useState(article_category);
  const [id] = useState(article_id);
  const [uid, setUID] = useState(null);
  const [isLoadingUID, setIsLoadingUID] = useState(true);
  const [uidError, setUIDError] = useState(null);
  const getUID = async (category, id) => {
    try {
      let findArticle = await db
        .collection(category)
        .where("id", "==", parseInt(id))
        .get();
      let uid = findArticle.docs.map((doc) => doc.id)[0];
      setUID(uid);
      return uid;
    } catch (e) {
      setUIDError("No Articles Found");
      return e;
    } finally {
      setIsLoadingUID(false);
    }
  };
  useEffect(() => {
    getUID(category, id);
  }, [category, id]);
  return [uid, isLoadingUID, uidError, getUID];
}

export const UpdateField = async (uid, category, field, value) => {
  try {
    await db
      .collection(category)
      .doc(uid)
      .update({
        [`${field}`]: value,
      });
    return true;
  } catch (e) {
    return false;
  } finally {
    return true;
  }
};
export const AddPost = async (values) => {
  try {
    await db.collection(values.category).add(values);
    return true;
  } catch (e) {
    return false;
  } finally {
    return true;
  }
};
export const DeletePost = async (category, uid) => {
  try {
    await db.collection(category).doc(uid).delete();
    return true;
  } catch (e) {
    return false;
  } finally {
    return true;
  }
};
export const upload = (data) => {
  return new Promise((resolve, reject) => {
    let id = Math.floor(Math.random() * 800);
    var uploadTask = firebase
      .storage()
      .ref("/content")
      .child(`/${id}`)
      .put(data);
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        switch (snapshot.state) {
          case firebase.storage.TaskState.RUNNING:
            break;
          case firebase.storage.TaskState.PAUSED:
            break;
          default:
        }
      },
      (error) => {
        reject(error);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          resolve(downloadURL);
        });
      }
    );
  });
};
