import React, { useMemo, useCallback, useState } from "react";
import imageExtensions from "image-extensions";
import isUrl from "is-url";
import { Editor, Transforms, createEditor } from "slate";
import {
  Slate,
  useSlate,
  Editable,
  useSelected,
  useFocused,
  withReact,
} from "slate-react";
import {
  FaBold,
  FaItalic,
  FaQuoteRight,
  FaUnderline,
  FaCode,
  FaHeading,
  FaListOl,
  FaListUl,
  FaImage,
} from "react-icons/fa";
import { withHistory } from "slate-history";
import { css } from "emotion";
import isHotkey from "is-hotkey";
import UploadImage from "./imageUploader";
import { Button, Toolbar } from "./components";
const LIST_TYPES = ["numbered-list", "bulleted-list"];
const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
};

const ImagesExample = ({ setRichValue, richValue, isEditable }) => {
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const [showUploadImage, setShowUploadImage] = useState(false);

  const [imageUrl] = useState(null);
  console.log("imageUrl", imageUrl);
  const editor = useMemo(
    () => withImages(withHistory(withReact(createEditor()))),
    []
  );
  const updateImageUrl = (url) => {
    console.log("update this inmage,,,", url);
    insertImage(editor, url);
  };
  return (
    <Slate
      editor={editor}
      value={richValue}
      onChange={(value) => setRichValue(value)}
    >
      {
        <UploadImage
          updateImageUrl={updateImageUrl}
          setShowUploadImage={setShowUploadImage}
          isVisible={showUploadImage}
        />
      }
      {isEditable && (
        <Toolbar>
          <InsertImageButton
            imageUrl={imageUrl}
            setShowUploadImage={setShowUploadImage}
          />
          <MarkButton format="bold" icon="format_bold" />
          <MarkButton format="italic" icon="format_italic" />
          <MarkButton format="underline" icon="format_underlined" />
          <BlockButton format="heading-two" icon="heading_two" />
          <BlockButton format="numbered-list" icon="number_list" />
          <BlockButton format="bulleted-list" icon="format_list_bulleted" />
        </Toolbar>
      )}

      <Editable
        renderElement={(props) => <Element {...props} />}
        renderLeaf={renderLeaf}
        placeholder="Enter some rich text…"
        spellCheck
        onKeyDown={(event) => {
          for (const hotkey in HOTKEYS) {
            if (isHotkey(hotkey, event)) {
              event.preventDefault();
              const mark = HOTKEYS[hotkey];
              toggleMark(editor, mark);
            }
          }
        }}
      />
    </Slate>
  );
};

const MarkButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      {icon === "format_bold" && <FaBold />}
      {icon === "format_italic" && <FaItalic />}
      {icon === "format_underlined" && <FaUnderline />}
      {icon === "code" && <FaCode />}
      {icon === "H1" && <FaHeading />}
    </Button>
  );
};
const BlockButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isBlockActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      {icon === "heading_two" && <FaHeading />}
      {icon === "format_quote" && <FaQuoteRight />}
      {icon === "number_list" && <FaListOl />}
      {icon === "format_list_bulleted" && <FaListUl />}
    </Button>
  );
};
const withImages = (editor) => {
  const { insertData, isVoid } = editor;

  editor.isVoid = (element) => {
    return element.type === "image" ? true : isVoid(element);
  };

  editor.insertData = (data) => {
    const text = data.getData("text/plain");
    const { files } = data;

    if (files && files.length > 0) {
      for (const file of files) {
        const reader = new FileReader();
        const [mime] = file.type.split("/");

        if (mime === "image") {
          reader.addEventListener("load", () => {
            const url = reader.result;
            insertImage(editor, url);
          });

          reader.readAsDataURL(file);
        }
      }
    } else if (isImageUrl(text)) {
      insertImage(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

const insertImage = (editor, url) => {
  const text = { text: "" };
  const image = { type: "image", url, children: [text] };
  Transforms.insertNodes(editor, image);
};

const Element = (props) => {
  const { attributes, children, element } = props;
  switch (element.type) {
    case "block-quote":
      return <blockquote {...attributes}>{children}</blockquote>;
    case "bulleted-list":
      return <ul {...attributes}>{children}</ul>;
    case "heading-one":
      return <h1 {...attributes}>{children}</h1>;
    case "heading-two":
      return <h2 {...attributes}>{children}</h2>;
    case "list-item":
      return <li {...attributes}>{children}</li>;
    case "numbered-list":
      return <ol {...attributes}>{children}</ol>;
    case "image":
      return <ImageElement {...props} />;
    default:
      return <p {...attributes}>{children}</p>;
  }
};
const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};
const ImageElement = ({ attributes, children, element }) => {
  const selected = useSelected();
  const focused = useFocused();
  return (
    <div {...attributes}>
      <br /> <br />
      <div contentEditable={false}>
        <img
          alt="rich"
          src={element.url}
          className={css`
            display: block;
            max-width: 100%;
            max-height: 20em;
            box-shadow: ${selected && focused ? "0 0 0 3px #B4D5FF" : "none"};
          `}
        />
      </div>
      {children}
    </div>
  );
};

const InsertImageButton = ({ setShowUploadImage }) => {
  return (
    <Button
      onMouseDown={(event) => {
        event.preventDefault();
        setShowUploadImage(true);
      }}
    >
      <FaImage />
    </Button>
  );
};

const isImageUrl = (url) => {
  if (!url) return false;
  if (!isUrl(url)) return false;
  const ext = new URL(url).pathname.split(".").pop();
  return imageExtensions.includes(ext);
};
const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => n.type === format,
  });

  return !!match;
};
const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) => LIST_TYPES.includes(n.type),
    split: true,
  });

  Transforms.setNodes(editor, {
    type: isActive ? "paragraph" : isList ? "list-item" : format,
  });

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};
const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};
const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

export default ImagesExample;
