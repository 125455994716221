import React from "react";
import Routing from "./components/Routing";
import { BrowserRouter } from "react-router-dom";
import "./app.scss";
function App(props) {
  return (
    <BrowserRouter>
      <Routing />
    </BrowserRouter>
  );
}

export default App;
