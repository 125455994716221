import React, { useState } from "react";
import { Form, Input, Button, Alert } from "antd";
import { useHistory } from "react-router-dom";
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const tailLayout = {
  //   wrapperCol: { span: 14 },
};
const LoginPage = () => {
  const history = useHistory();
  const [error, setError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const onFinish = (values) => {
    setSubmitting(true);
    setError(false);
    let { password, username } = values;
    if (
      username === process.env.REACT_APP_USERNAME &&
      password === process.env.REACT_APP_PASSWORD
    ) {
      setTimeout(() => {
        setSubmitting(false);
        localStorage.setItem("authenticated", true);
        history.push(`/home`);
        window.location.reload();
      }, 1000);
    } else {
      setTimeout(() => {
        setError(true);
        setSubmitting(false);
      }, 1000);
    }
  };

  return (
    <Form
      style={{ maxWidth: "350px", width: "100%", margin: "0 auto" }}
      {...layout}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
    >
      <Form.Item
        label="Username"
        name="username"
        rules={[{ required: true, message: "Please input your username!" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: "Please input your password!" }]}
      >
        <Input.Password />
      </Form.Item>
      {error && (
        <Form.Item>
          <Alert type="error" message="Invalid credentials" />
        </Form.Item>
      )}
      <Form.Item {...tailLayout}>
        <Button
          style={{ width: "100%" }}
          loading={submitting}
          type="primary"
          htmlType="submit"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};
export default LoginPage;
