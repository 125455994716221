import React from "react";
import Featured from "../widgets/Featured";
import ArticleCard from "../widgets/ArticleCard";
import { Row } from "antd";
// import moment from "moment";
// const timestamp = moment().valueOf();
const Home = () => {
  return (
    <div>
      <Featured />
      <Row>
        <ArticleCard type="news" />
      </Row>
    </div>
  );
};
export default Home;
