import React from "react";
import { Card, Empty, Col, Row } from "antd";
import "./widgets.scss";
import { FetchArticles } from "../ApiHooks";
import { useHistory } from "react-router-dom";
import moment from "moment";
const { Meta } = Card;

const ArticleCard = ({ type }) => {
  let history = useHistory();
  const [data, isLoading, error] = FetchArticles(type);
  return (
    <div className="article-card-widget">
      {isLoading ? (
        <Card style={{ width: "100%", marginTop: 16 }} loading={true} />
      ) : error ? (
        <Empty description={<span>{error}</span>} />
      ) : (
        <Row gutter={[16, 16]}>
          {data.map((article, key) => {
            return (
              <Col xs={24} sm={12} md={8} key={key}>
                <Card
                  key={key}
                  className="article-card-widget-card"
                  onClick={() => history.push(`/post/${type}/${article.id}`)}
                  cover={<img alt="example" src={article.image} />}
                >
                  <Meta
                    title={article.title}
                    description={`${article.author} | ${moment(
                      article.timestamp
                    ).format("MMM Do, YYYY")}`}
                  />
                </Card>
              </Col>
            );
          })}
        </Row>
      )}
    </div>
  );
};
export default ArticleCard;
