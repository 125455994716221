import React, { useState } from "react";
import { Modal, Button } from "antd";
import { Upload, Form } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { upload } from "../ApiHooks";
import $ from "jquery";

const UploadImage = ({ isVisible, setShowUploadImage, updateImageUrl }) => {
  const [uploadImageForm] = Form.useForm();
  const [, setUploaded] = useState(0);
  const [fileList, setfFileList] = useState([]);
  const [defaultFileList, setDefaultFileList] = useState([]);

  const onFinish = async (values) => {
    let { content } = values;
    const content_url = await upload(
      content.file[0].originFileObj,
      content.file[0].name
    );
    updateImageUrl(content_url);
    setShowUploadImage(false);
    setDefaultFileList([]);
    setUploaded(0);
    setfFileList([]);
    $(
      "#article_item_form .ant-upload-list-item-card-actions .anticon-delete"
    ).trigger("click");
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    e.file.status === "removed" ? setUploaded(0) : setUploaded(1);
    return e && e.fileList;
  };
  const props = {
    onRemove: () => {
      setfFileList([]);
    },
    beforeUpload: (file) => {
      setfFileList([...fileList, file]);
      return false;
    },
  };
  return (
    <Modal
      title="Add image"
      visible={isVisible}
      footer={[
        <Button
          form="article_item_form"
          key="submit"
          htmlType="submit"
          type="primary"
        >
          Continue
        </Button>,
      ]}
      onCancel={() => setShowUploadImage(false)}
      onOK={() => console.log("ok")}
    >
      <Form onFinish={onFinish} id="article_item_form" form={uploadImageForm}>
        <Form.Item
          hasFeedback={false}
          name={["content", "file"]}
          getValueFromEvent={normFile}
        >
          <Upload defaultFileList={defaultFileList} multiple={false} {...props}>
            <Button disabled={fileList.length >= 1 ? true : false}>
              <UploadOutlined /> Select File
            </Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default UploadImage;
